import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
const TblOrder = () => {
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${Config.API_URL}/api/formdata`);
  //       console.log(response.data)
  //       setData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  
  return (
    <div className="table-container" style={{ overflowX: "auto"}}>
      <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive" >
        <thead>
          <tr>
            <th className="customStyle">Full Name</th>
            <th className="customStyle">Instagram</th>
            <th className="customStyle">Facebook</th>
            <th className="customStyle">Twitter</th>
            <th className="customStyle">YouTube</th>
            <th className="customStyle">Custom Social</th>
            <th className="customStyle">Gender</th>
            <th className="customStyle">Whatsapp</th>
            <th className="customStyle">Phone</th>
            <th className="customStyle">Custom MarketPlace</th>
            <th className="customStyle">Custom Review</th>
            <th className="customStyle">Custom Social</th>
            <th className="customStyle">Custom Profile Link</th>
            <th className="customStyle">Email</th>
            <th className="customStyle">Order</th>
            <th className="customStyle">Date</th>
            <th className="customStyle">Student</th>
            <th className="customStyle">College</th>
            <th className="customStyle">Manager</th>
            <th className="customStyle">Whatsapp Group</th>
            <th className="customStyle">Pin Code</th>
            <th className="customStyle">City</th>
            <th className="customStyle">Product Question</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="customStyle">{item.FullName }</td>
              <td className="customStyle">{item.AuthInstagram}</td>
              <td className="customStyle">{item.AuthFacebook }</td>
              <td className="customStyle">{item.AuthTwitter}</td>
              <td className="customStyle">{item.AuthYouTube }</td>
              <td className="customStyle">{item.AuthCustomSocial }</td>
              <td className="customStyle">{item.gender }</td>
              <td className="customStyle">{item.whatsapp }</td>
              <td className="customStyle">{item.Phone }</td>
              <td className="customStyle">{item.customMarketPlaceName}</td>
              <td className="customStyle">{item.review}</td>
              <td className="customStyle">{item.AuthCustomSocial}</td>
              <td className="customStyle">{item.customForoum}</td>
              <td className="customStyle">{item.email }</td>
              <td className="customStyle">{item.order }</td>
              <td className="customStyle" >{item.date }</td>
              <td className="customStyle">{item.student }</td>
              <td className="customStyle">{item.college}</td>
              <td className="customStyle">{item.manger }</td>
              <td className="customStyle">{item.whatsappGroup}</td>
              <td className="customStyle">{item.pinCode }</td>
              <td className="customStyle">{item.city }</td>
              <td className="customStyle">{item.productSS }</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TblOrder;
