import React from 'react';

const Redirect = () => {
    console.log("Redirect component rendered");
    return (
        <div>
            <p>Thank You!</p>
            <h1>Waiting for approval</h1>
        </div>
    );
};

export default Redirect;
