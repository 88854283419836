import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation } from 'react-router-dom';

const ApproverTable = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const location = useLocation();
  const campaignName = location.state?.campaignName;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/refund`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/refund-order`);
        setData1(response.data);  // Use response.data instead of response.data1
      } catch (error) {
        console.error('Error fetching data1:', error);
      }
    };

    fetchData1();
  }, []);

  const handleApprove = async (order) => {
    try {
      const payload = { orderId: order }; // Check if 'order' is the right reference

      console.log('Payload:', payload); // Log the payload to see if order is correct

      const response = await axios.post(`${Config.API_URL}/api/approve-payment`, payload);

      setData((prevData) =>
        prevData.map((item) =>
          item.order === order ? { ...item, status: 'Approved' } : item
        )
      );
      console.log('Approval submitted');
    } catch (error) {
      console.error('Error approving order:', error);
      console.log('Response:', error.response?.data);
    }
  };
  const handleCancel = async (orderId) => {
    try {
      await axios.post(`${Config.API_URL}/api/cancel-order`, { orderId });
      setData((prevData) =>
        prevData.map((item) =>
          item.orderId === orderId ? { ...item, status: 'Canceled' } : item
        )
      );
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="table is-bordered" style={{ overflowX: 'auto' }}>
        <table className="table is-fullwidth">
          <thead>
            <tr>

              <th className="customStyle">Order ID</th>
              <th className="customStyle">Quantity</th>
              <th className="customStyle">Invoice Number</th>
              <th className="customStyle">Product Amount</th>
              <th className="customStyle">Deliverable Link</th>
              <th className="customStyle">Bank Name</th>
              <th className="customStyle">Ifsc</th>
              <th className="customStyle">Beneficiary Name</th>
              <th className="customStyle">Account Number</th>
              <th className="customStyle">UPI Number</th>
              <th className="customStyle">Manager</th>
              <th className="customStyle">Date</th>
              <th className="customStyle">Student</th>
              <th className="customStyle">College</th>
              <th className="customStyle">Whatsapp Group</th>
              <th className="customStyle">Pin Code</th>
              <th className="customStyle">City</th>
              <th className="customStyle">Product Name</th>
              <th className="customStyle">Order Delivered Screen Shot</th>
              <th className="customStyle">Submitting Rating SS</th>

              {/* New Columns for Data1 */}
              <th className="customStyle">Order ID</th>
              <th className="customStyle">Full Name</th>
              <th className="customStyle">Instagram</th>
              <th className="customStyle">Facebook</th>
              <th className="customStyle">Twitter</th>
              <th className="customStyle">YouTube</th>
              <th className="customStyle">Custom Social</th>
              <th className="customStyle">Gender</th>
              <th className="customStyle">Whatsapp</th>
              <th className="customStyle">Phone</th>
              <th className="customStyle">Custom MarketPlace</th>
              <th className="customStyle">Custom Review</th>
              <th className="customStyle">Custom Profile Link</th>
              <th className="customStyle">Email</th>

              <th className="customStyle">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data1 && data1.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  {/* <td className="customStyle">{data1[index]?.FullName || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthInstagram || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthFacebook || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthTwitter || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthYouTube || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthCustomSocial || ''}</td>
                  <td className="customStyle">{data1[index]?.gender || ''}</td>
                  <td className="customStyle">{data1[index]?.whatsapp || ''}</td>
                  <td className="customStyle">{data1[index]?.Phone || ''}</td>
                  <td className="customStyle">{data1[index]?.customMarketPlaceName || ''}</td>
                  <td className="customStyle">{data1[index]?.review || ''}</td>
                  <td className="customStyle">{data1[index]?.customForoum || ''}</td>
                  <td className="customStyle">{data1[index]?.email || ''}</td> */}
                  {/* Data from `data` */}
                  <td className="customStyle">{item.order}</td>
                  <td className="customStyle">{item.inputValue1}</td>
                  <td className="customStyle">{item.invoiceReceived}</td>
                  <td className="customStyle">{data1[index]?.productAmount || ''}</td>
                  <td className="customStyle">{item.inputValue2}</td>
                  <td className="customStyle">{item.bankName}</td>
                  <td className="customStyle">{item.ifsc}</td>
                  <td className="customStyle">{item.beneficiaryName}</td>
                  <td className="customStyle">{item.accountNumber}</td>
                  <td className="customStyle">{item.UPI}</td>
                  <td className="customStyle">{item.manger}</td>
                  <td className="customStyle">{data1[index]?.date || ''}</td>
                  <td className="customStyle">{item.student}</td>
                  <td className="customStyle">{data1[index]?.collegeName || ''}</td>
                  <td className="customStyle">{item.whatsappGroup}</td>
                  <td className="customStyle">{data1[index]?.pinCode || ''}</td>
                  <td className="customStyle">{data1[index]?.city || ''}</td>
                  <td className="customStyle">{item.inputValue}</td>
                  <td className="customStyle">
                    {item.inputValue3 && (
                      <img
                        src={`${Config.API_URL}/${item.inputValue3}`}
                        alt="Screenshot 1"
                        style={{ width: '100px', height: 'auto' }}
                      />
                    )}
                  </td>
                  <td className="customStyle">
                    {item.inputValue4 && (
                      <img
                        src={`${Config.API_URL}/${item.inputValue4}`}
                        alt="Screenshot 2"
                        style={{ width: '100px', height: 'auto' }}
                      />
                    )}
                  </td>


                  {/* Data from `data1`, corresponding to the same row */}
                  <td className="customStyle">{data1[index]?.order || ''}</td>
                  <td className="customStyle">{data1[index]?.FullName || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthInstagram || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthFacebook || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthTwitter || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthYouTube || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthCustomSocial || ''}</td>
                  <td className="customStyle">{data1[index]?.gender || ''}</td>
                  <td className="customStyle">{data1[index]?.whatsapp || ''}</td>
                  <td className="customStyle">{data1[index]?.Phone || ''}</td>
                  <td className="customStyle">{data1[index]?.customMarketPlaceName || ''}</td>
                  <td className="customStyle">{data1[index]?.review || ''}</td>
                  <td className="customStyle">{data1[index]?.customForoum || ''}</td>
                  <td className="customStyle">{data1[index]?.email || ''}</td>
                  <td className="customStyle">
                    <button onClick={() => handleApprove(item.order)} className="button is-primary">
                      Approve
                    </button>
                    <button onClick={() => handleCancel(item.orderId)} className="button is-danger ml-2">
                      Cancel
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="33">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApproverTable;
