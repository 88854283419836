import React, { useState } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RefundTemp = () => {
  const navigate = useNavigate();
  const [AuthInstagram, setAuthInstagram] = useState(false);
  const [AuthFacebook, setAuthFacebook] = useState(false);
  const [AuthTwitter, setAuthTwitter] = useState(false);
  const [AuthYouTube, setAuthYouTube] = useState(false);
  const [AuthCustomSocial, setAuthCustomSocial] = useState('');
  const [customMarketPlaceName, setCustomMarketPlaceName] = useState('');
  const [customForoum, setCustomForoum] = useState('');
  const [FullName, setFullName] = useState(false);
  const [review, setReview] = useState(''); // Initialize as an empty string
  const [gender, setGender] = useState(false);
  const [student, setStudent] = useState(false);
  const [productSS, setProductSS] = useState(false);
  const [order, setOrder] = useState(false);
  const [city, setCity] = useState(false);
  const [pinCode, setPinCode] = useState(false);
  const [UPI, setUPI] = useState(false);
  const [bankName, setbankName] = useState(false);
  const [accountNumber, setAccoutNumber] = useState(false);
  const [ifsc, setIfsc] = useState(false);
  const [beneficiaryName, setbeneficiaryName] = useState(false);
  const [whatsappGroup, setWhatsappGroup] = useState(false);
  const [manager, setManager] = useState(false);
  const [Phone, setPhone] = useState(false);
  const [date, setDate] = useState(false);
  const [college, setCollege] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [email, setEmail] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState(false);
  const [product1, setproduct1] = useState(false);
  const [products, setProducts] = useState([
    { name: '', quantity: '', ss: null, amount: '' }
  ]);
  //const [productSS, setProductSS] = useState(false);
  //const [inputValue, setInputValue] = useState('');
  const [orderDeliveredSS, setOrderDeliveredSS] = useState('');
  const [amazonReviewLink, setAmazonReviewLink] = useState('');
  const [reviewSS, setReviewSS] = useState('');


  const [invoiceReceived, setInvoiceReceived] = useState('Invoice received ');
  const [invoiceNumber, setInvoiceNumber] = useState('Invoice Number');




  const [inputValue, setInputValue] = useState('Ordered and Reviewed/product/Task/Store Link of Review');
  const [inputValue1, setInputValue1] = useState('Quantity');
  const [inputValue2, setInputValue2] = useState('Amazon Verified Review Link/Deliverble Live Link');
  const [inputValue3, setInputValue3] = useState('Order Delivered Screen Shot');
  const [inputValue4, setInputValue4] = useState('Amazon Verified Review SS/Deliverables Live SS/Submitting RAting SS');
  const [starRating, setStarRating] = useState('Value of Star Rating Given');
  const [sellerFeedbackScreenshot, setsellerFeedbackScreenshot] = useState('Seller Feedback SS');
  // const [customMarketPlaceName, setCustomMarketPlaceName] = useState('');
  const [profileVisibility, setProfileVisibility] = useState('public');
  const location = useLocation();
  const { campaignName } = location.state || '';

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Collect the template data into an object
    const TemplateData = {
      AuthInstagram,
      AuthFacebook,
      AuthTwitter,
      AuthYouTube,
      customMarketPlaceName,
      FullName,
      gender,
      college,
      whatsapp,
      email,
      date,
      manager,
      whatsappGroup,
      pinCode,
      UPI,
      accountNumber,
      ifsc,
      bankName,
      paymentMethod,
      beneficiaryName,
      productSS,
      order,
      city,
      Phone,
      student,
      product1,
      review,
      customForoum,
      AuthCustomSocial,
      campaignName,
      inputValue,
      inputValue1,
      inputValue2,
      inputValue3,
      inputValue4,
      starRating,
      sellerFeedbackScreenshot,
      invoiceReceived,
      invoiceNumber,
      profileVisibility,
      products, // include the products array
    };

    try {
      // Send the template data to the backend
      const response = await axios.post(`${Config.API_URL}/api/tepmlateRefund`, TemplateData);
      console.log('Template data submitted:', response.data);

      // Redirect to another page after submission
      navigate('/admin', { state: { campaignName } });
    } catch (error) {
      console.error('Error submitting template data:', error);
    }
  };
  const handleInputChange = (index, event) => {
    const values = [...products];
    if (event.target.name === "ss") {
      values[index][event.target.name] = event.target.files[0];
    } else {
      values[index][event.target.name] = event.target.value;
    }
    setProducts(values);
  };
  const handleAddFields = () => {
    setProducts([...products, { name: '', quantity: '', ss: null, amount: '' }]);
  };

  const handleRemoveFields = index => {
    const values = [...products];
    values.splice(index, 1);
    setProducts(values);
  };
  return (
    <div className='container'>
      <div className='container1 mt-5'>
        <figure className="image is-64x64">
          {/* <img src={logo} alt="Logo" /> */}
        </figure>
      </div>
      <form onSubmit={handleSubmit}>
        <h1 className='title has-text-centered mt-3'>Form</h1>
        {/* <p><strong>Template</strong> {campaignName}</p> */}
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />

            <input
              className="input is-small"
              type="text"
              value={AuthCustomSocial}
              onChange={(e) => setAuthCustomSocial(e.target.value)}
              placeholder=" Custom Platform Name "
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Account Holder Name
          </label>
        </div>


        <div className="field is-small is-flex is-align-items-center is-fullwidth" >
          <label className="checkbox" style={{ marginRight: '10px' }}>
            <input
              type="checkbox"
              checked={productSS}
              onChange={(e) => setProductSS(e.target.checked)}
            />
            Choose
          </label>

          <input
            className="input is-small"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Ordered and Reviewed/product/Task/Store Link of Review "
            style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
          />

          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            <label className="label" style={{ marginBottom: '0px' }}>Optional</label>
            <div className="control">
              <input
                className="input is-small"
                type="text"
                value={inputValue3}
                onChange={(e) => setInputValue3(e.target.value)}
                placeholder="Order Delivered SS"
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>

            <div className="control">

              <input
                className="input is-small"
                type="text"
                value={inputValue2}
                onChange={(e) => setInputValue2(e.target.value)}
                placeholder="Amazon Verified Review Link/Deliverble Live Link"
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            {/* <label className="label" style={{ marginBottom: '0px' }}>Optional</label> */}
            <div className="control">

              <input
                className="input is-small"
                type="text"
                value={inputValue4}
                onChange={(e) => setInputValue4(e.target.value)}
                placeholder="Amazon Verified Review SS/Deliverables Live SS/Submitting RAting SS"
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            <label className="label" style={{ marginBottom: '0px' }}>Optional</label>
            <div className="control">

              <input
                className="input is-small"
                type="text"
                value={starRating}
                onChange={(e) => setStarRating(e.target.value)}
                placeholder="Value of Star Rating Given "
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            <label className="label" style={{ marginBottom: '0px' }}>Optional</label>
            <div className="control">

              <input
                className="input is-small"
                type="text"
                value={sellerFeedbackScreenshot}
                onChange={(e) => setsellerFeedbackScreenshot(e.target.value)}
                placeholder="Seller Feedback SS"
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            <label className="label" style={{ marginBottom: '0px' }}>Optional</label>
            <div className="control">
              <input
                className="input is-small"
                type="text"
                value={invoiceReceived}
                onChange={(e) => setInvoiceReceived(e.target.value)}
                placeholder="Invoice received "
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <div className="field" style={{ marginRight: '10px' }}>
            <label className="label" style={{ marginBottom: '0px' }}>Optional</label>
            <div className="control">
              <input
                className="input is-small"
                type="text"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                placeholder="Invoice Number"
                style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
              />
            </div>
          </div>
        </div>


        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={order}
              onChange={(e) => setOrder(e.target.checked)}
            />
            Order Id
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={paymentMethod}
              onChange={(e) => setpaymentMethod(e.target.checked)}
            />
            Account Type(UPI or BankAccount)
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={product1}
              onChange={(e) => setproduct1(e.target.checked)}
            />
            Select Product
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={UPI}
              onChange={(e) => setUPI(e.target.checked)}
            />
            UPI Id
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={accountNumber}
              onChange={(e) => setAccoutNumber(e.target.checked)}
            />
            Bank Account Number
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={ifsc}
              onChange={(e) => setIfsc(e.target.checked)}
            />
            Ifsc Code
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={beneficiaryName}
              onChange={(e) => setbeneficiaryName(e.target.checked)}
            />
            Beneficiary Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={bankName}
              onChange={(e) => setbankName(e.target.checked)}
            />
            Bank Name
          </label>
        </div>

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={whatsappGroup}
              onChange={(e) => setWhatsappGroup(e.target.checked)}
            />
            Have you joined WhatsApp Group
          </label>
        </div>

        {/* <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={city}
              onChange={(e) => setCity(e.target.checked)}
            />
            Have You Joined WhatsApp Group
          </label>
        </div> */}
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={city}
              onChange={(e) => setCity(e.target.checked)}
            />
            Agree to{' '}
            <a href="//hobo.video/terms-conditions-for-an-influencer" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary is-rounded is-fullwidth mt-5" type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RefundTemp;
