import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js'; 

const CampaignForm = () => {
    const [AuthName, setAuthName] = useState('');
    const [AuthContact, setAuthContact] = useState('');
    const [AuthEmail, setAuthEmail] = useState('');
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    // Fetch users from the backend
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${Config.API_URL}/api/users`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }

                const usersData = await response.json();
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Collect selected managers and leads
        const managers = users.filter(user => user.role !== 'Lead' && user.isChecked).map(user => user.userName);
        const leads = users.filter(user => user.role === 'Lead' && user.isChecked).map(user => user.userName);

        // Construct form data object
        const formData = {
            name: AuthName,
            campaignName: AuthContact,
            campaignRulesDescription: AuthEmail,
            managers, // Array of selected manager names
            leads     // Array of selected lead names
        };

        try {
            // Send form data to the backend
            const response = await axios.post(`${Config.API_URL}/api/campaigns`, formData);
            console.log('Form data submitted', response.data);
            // Navigate to another page or show success message
            navigate('/campaign2', { state: { campaignName: AuthContact, managers } });
            console.log(AuthContact);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (id) => (event) => {
        const updatedUsers = users.map(user => 
            user._id === id ? { ...user, isChecked: event.target.checked } : user
        );
        setUsers(updatedUsers);
    };

    return (
        <div className='container'>
            <div className='container1 mt-5'>
                <figure className="image is-64x64"></figure>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <h1 className='title has-text-centered mt-5'>Campaign</h1>
                </div>
                <div className='field is-small'>
                    <label className="label has-text-left">Campaign Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Campaign Name"
                            value={AuthContact}
                            onChange={(e) => setAuthContact(e.target.value)}
                        />
                    </div>
                </div>
                <div className='field '>
                    <label className="label has-text-left">Campaign Rules Description*</label>
                    <div className="control">
                        <textarea
                            className="textarea" style={{ borderRadius: "20px" }}
                            value={AuthEmail}
                            onChange={(e) => setAuthEmail(e.target.value)}
                            placeholder="Campaign rule description"
                        ></textarea>
                    </div>
                </div>
                <div className='field'>
                    <div className="control">
                        <div className='column'>
                            <div className='my-2 has-text-left'>
                                <strong>Choose Your Campaign Manager(s)*</strong>
                            </div>
                            {users.filter(user => user.role !== 'Lead').map(user => (
                                <div key={user._id} className="checkboxContainer" style={{ display: 'inline-flex', flexDirection: 'row', marginRight: '8px' }}>
                                    <input
                                        type="checkbox"
                                        checked={user.isChecked || false}
                                        onChange={handleCheckboxChange(user._id)}
                                        className="checkbox"
                                    />
                                    <label className="checkboxLabel" style={{ marginLeft: '8px' }}>{user.userName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <div className="control">
                        <div className='column'>
                            <div className='my-2 has-text-left'>
                                <strong>Choose Your Lead(s)*</strong>
                            </div>
                            {users.filter(user => user.role === 'Lead').map(user => (
                                <div key={user._id} className="checkboxContainer" style={{ display: 'inline-flex', flexDirection: 'row', marginRight: '9px' }}>
                                    <input
                                        type="checkbox"
                                        checked={user.isChecked || false}
                                        onChange={handleCheckboxChange(user._id)}
                                        className="checkbox"
                                    />
                                    <label className="checkboxLabel" style={{ marginLeft: '8px' }}>{user.userName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <button className="button is-primary is-fullwidth mt-5 is-rounded" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CampaignForm;
