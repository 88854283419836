import React, { useState } from 'react';
import topImage from './img/download.jpeg';
import logo from "./img/Logo (1).png";

const CustomerCard = () => {
    // Styles for the top image card
    const topImageStyle = {
        backgroundImage: `url(${topImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '150px', // Adjust height as needed
        width: '390px', // Full width to match the button card below
        borderRadius: '4px',
        marginBottom: '-15px',
        marginLeft: '-14px',
        marginTop: '-13px',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '10px',
        left: '35px',
        width: '40px',
        height: '30px',
        top: "170px"
    };
    const title = {
        title: "Subtitle"
    };
    const brandName = {
        brandName: "BrandName"
    }

    const followersCriteria = {
        followersCriteria: "followersCriteria"
    }
    const deliverables = {
        deliverables: "deliverables"
    }
    const GiveProductsWorth = {
        GiveProductWorth: "Give Products Worth"
    }
    const dealtype = {
        dealtype: "deal type"
    }
    // State variables for campaign status
    const Participate = true;
    const PaymentsFormFilled = true;
    const PaymentsMadeCampaignCompleted = false;

    // Progress Line Style
    const getProgressWidth = () => {
        if (Participate && PaymentsFormFilled && PaymentsMadeCampaignCompleted) return "100%";
        if (Participate && PaymentsFormFilled) return "66%";
        if (Participate) return "33%";
        return "0%";
    };
    const getProgressPercentage = () => {
        if (Participate && PaymentsFormFilled && PaymentsMadeCampaignCompleted) return "100% - Payment Made & Campaign Successfully Completed";
        if (Participate && PaymentsFormFilled) return "66% - Payments Form Filled";
        if (Participate) return "33% - Participated";
        return "0%";
    };
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState("");

    const handleMouseEnter = (e) => {
        setTooltipVisible(true);
        setTooltipContent(getProgressPercentage());
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <div className='is-max-desktop'>
            <div >
                {/* Button Card */}
                <div className="card-content">
                    <div className="box mt-6 ml-5" style={{ width: 400, height: 630, overflowX: 'hidden' }}>
                        <div className="box" style={topImageStyle}>
                            {/* Image logo in bottom-left corner */}
                            <img src={logo} alt="Logo" style={logoStyle} />
                        </div>
                        <br />
                        <div>
                            {/* Brand Name and Followers Criteria Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
                                    <p className='has-text-weight-bold'>{brandName.brandName}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "125px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light mr-5" >Followers Criteria</p>
                                    <p className='has-text-weight-bold mr-5'>{followersCriteria.followersCriteria}</p>
                                </div>
                            </div>

                            {/* deliverables and Give Products Worth Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
                                    <p className='has-text-weight-bold'>{deliverables.deliverables}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "130px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
                                    <p className='has-text-weight-bold mr-1'>{GiveProductsWorth.GiveProductWorth}</p>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deal Type</p>
                                    <p className='has-text-weight-bold'>{dealtype.dealtype}</p>
                                </div>
                            </div>

                            {/* Progress Line Section */}
                            <div style={{ marginTop: "20px", position: 'relative' }}>
                                {/* Points Above the Progress Line */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="icon">
                                            <i className="fas fa-check-circle"></i>
                                        </span>
                                        <br />
                                        Participated
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="icon">
                                            <i className="fas fa-pencil-alt"></i>
                                        </span>
                                        <br />
                                        Payments Form Filled
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="icon">
                                            <i className="fas fa-check-double"></i>
                                        </span>
                                        <br />
                                        Payment Made & Campaign Successfully Completed
                                    </div>
                                </div>
                                {/* Progress Line */}
                                <div style={{ width: '100%', height: '5px', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        style={{
                                            width: getProgressWidth(),
                                            height: '100%',
                                            backgroundColor: 'black', // Set line color to black
                                            borderRadius: '5px',
                                            transition: 'width 0.5s ease-in-out'
                                        }}
                                    />
                                </div>
                                {/* Tooltip */}
                                {tooltipVisible && (
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '30px', // Adjust position as needed
                                        left: `${parseInt(getProgressWidth()) - 50}px`, // Center tooltip based on width
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        padding: '5px',
                                        zIndex: 10,
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                    }}>
                                        {tooltipContent}
                                    </div>
                                )}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', }}>
                                <button className="button is-small mt-3 has-text-info ">View Campaign Details</button>
                                <button className="button is-small mt-3  has-text-info ">Participate</button>
                                <button className="button is-small mt-3  has-text-info ">Add Refund Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CustomerCard;
