import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';

const CampaignDetails = () => {
    const [formData, setFormData] = useState({
        AuthContact: '',
        AuthEmail: '',
        productName: '',
        // Add other fields as needed
    });

    useEffect(() => {
        // Step 1: Fetch campaign data
        axios.get(`${Config.API_URL}/api/getCampaign`)
            .then(response => {
                const campaignData = response.data;
                setFormData(prevState => ({
                    ...prevState,
                    AuthContact: campaignData.campaignName,
                    AuthEmail: campaignData.campaignRulesDescription,
                }));
            })
            .catch(error => {
                console.error('Error fetching campaign data:', error);
            });

        // Step 2: Fetch product data
        axios.get(`${Config.API_URL}/api/product`)
            .then(response => {
                const productData = response.data;
                setFormData(prevState => ({
                    ...prevState,
                    productName: productData.productName,
                    // Add other product fields as needed
                }));
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <div className='container'>
            <div className='container1 mt-5'>
                <figure className="image is-64x64"></figure>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <h1 className='title has-text-centered mt-5'>Campaign</h1>
                </div>

                {/* Non-editable Campaign Name */}
                <div className='field is-small'>
                    <label className="label has-text-left">Campaign Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Campaign Name"
                            value={formData.AuthContact}
                            readOnly
                        />
                    </div>
                </div>

                {/* Non-editable Campaign Rules Description */}
                <div className='field'>
                    <label className="label has-text-left">Campaign Rules Description*</label>
                    <div className="control">
                        <textarea
                            className="textarea"
                            style={{ borderRadius: "20px" }}
                            value={formData.AuthEmail}
                            readOnly
                            placeholder="Campaign rule description"
                        ></textarea>
                    </div>
                </div>

                {/* Non-editable Product Name */}
                <div className="field is-small">
                    <label className="label has-text-left">Item Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Product Name"
                            value={formData.productName}
                            readOnly
                        />
                    </div>
                </div>

                {/* Submit Button */}
                {/* <div className="field">
                    <div className="control">
                        <button className="button is-primary is-fullwidth mt-5 is-rounded" type="submit">Submit</button>
                    </div>
                </div> */}
            </form>
        </div>
    );
};

export default CampaignDetails;
